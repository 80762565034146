<template>
  <b-modal
    id="campas-point-limit-create-modal"
    ref="campas-point-limit-create-modal"
    title="Create"
    centered
    hide-footer
    no-close-on-backdrop
    size="xl"
    button-size="sm"
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>
        <b-row>
          <!-- !! Optimizer -->
          <b-col cols="4">
            <validation-provider
              #default="{ errors }"
              name="Optimizer"
              rules="required"
            >
              <HeroVueSelect
                id="optimizer"
                v-model="campaspointlimit.user_id"
                label="Optimizer"
                :required="true"
                :clearable="false"
                :options="optimizerOptions"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Max Points -->
          <b-col cols="4">
            <validation-provider
              #default="{ errors }"
              name="Max Points"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="max_points"
                v-model="campaspointlimit.max_points"
                label="Max Points"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Remaining Points -->
          <b-col cols="4">
            <b-form-group
              label-for="remaining_points"
            >
              <template v-slot:label>
                <span style="font-size: 1rem; font-weight: 600;">
                  Remaining Points
                </span>
              </template>
              <div
                id="remaining_points"
              >
                {{ remaining_points }}
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <hr>
          </b-col>
          <!-- !! Hero Facebook Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Facebook"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_facebook_point"
                v-model="campaspointlimit.hero_facebook_point"
                label="Hero Facebook"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Google Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Google"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_google_point"
                v-model="campaspointlimit.hero_google_point"
                label="Hero Google"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Line Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Line"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_line_point"
                v-model="campaspointlimit.hero_line_point"
                label="Hero Line"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero TikTok Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero TikTok"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_tiktok_point"
                v-model="campaspointlimit.hero_tiktok_point"
                label="Hero TikTok"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Hero Instagram Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Instagram"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_instagram_point"
                v-model="campaspointlimit.hero_instagram_point"
                label="Hero Instagram"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero LinkedIn Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero LinkedIn"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_linkedin_point"
                v-model="campaspointlimit.hero_linkedin_point"
                label="Hero LinkedIn"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Grab Ads Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Grab Ads"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_grab_ads_point"
                v-model="campaspointlimit.hero_grab_ads_point"
                label="Hero Grab Ads"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Lazada Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Lazada"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_lazada_point"
                v-model="campaspointlimit.hero_lazada_point"
                label="Hero Lazada"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Hero Call Tracking Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Call Tracking"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_call_tracking_point"
                v-model="campaspointlimit.hero_call_tracking_point"
                label="Hero Call Tracking"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Pkkt Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Pkkt"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_pkkt_point"
                v-model="campaspointlimit.hero_pkkt_point"
                label="Hero Pkkt"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero YouTube Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero YouTube"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_youtube_point"
                v-model="campaspointlimit.hero_youtube_point"
                label="Hero YouTube"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Popin Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Popin"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_popin_point"
                v-model="campaspointlimit.hero_popin_point"
                label="Hero Popin"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Hero Taboola Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Taboola"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_taboola_point"
                v-model="campaspointlimit.hero_taboola_point"
                label="Hero Taboola"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Twitter Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Twitter"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_twitter_point"
                v-model="campaspointlimit.hero_twitter_point"
                label="Hero Twitter"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Shopee Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Shopee"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_shopee_point"
                v-model="campaspointlimit.hero_shopee_point"
                label="Hero Shopee"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Innity Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Innity"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_innity_point"
                v-model="campaspointlimit.hero_innity_point"
                label="Hero Innity"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Hero Teads Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Teads"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_teads_point"
                v-model="campaspointlimit.hero_teads_point"
                label="Hero Teads"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero LandingPage Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero LandingPage"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_landing_page_point"
                v-model="campaspointlimit.hero_landing_page_point"
                label="Hero LandingPage"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Viu Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Viu"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_viu_point"
                v-model="campaspointlimit.hero_viu_point"
                label="Hero Viu"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Influencer Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Influencer"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_influencer_point"
                v-model="campaspointlimit.hero_influencer_point"
                label="Hero Influencer"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Hero Joox Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Joox"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_joox_point"
                v-model="campaspointlimit.hero_joox_point"
                label="Hero Joox"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero GoBear Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero GoBear"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_gobear_point"
                v-model="campaspointlimit.hero_gobear_point"
                label="Hero GoBear"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero Criteo Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero Criteo"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_criteo_point"
                v-model="campaspointlimit.hero_criteo_point"
                label="Hero Criteo"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Hero FreakOut Points -->
          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Hero FreakOut"
              rules="required|min_value:0"
            >
              <HeroInputNumber
                id="hero_freakout_point"
                v-model="campaspointlimit.hero_freakout_point"
                label="Hero FreakOut"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="8" sm="2">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="2">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import CampasPointLimit from '@/models/Masters/CampasPointLimit'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    HeroInputNumber,
    HeroVueSelect,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,

      optimizerOptions: [],
      campaspointlimit: new CampasPointLimit({}),
    }
  },
  computed: {
    remaining_points() {
      return this.campaspointlimitsum(this.campaspointlimit)
    },
  },
  methods: {
    doCloseModal() {
      this.campaspointlimit = new CampasPointLimit({})
      this.$refs['campas-point-limit-create-modal'].hide()
    },

    async doLoadData() {
      this.showOverlay = true
      this.optimizerOptions = []
      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)
      try {
        const response = await axios.get('dropdown-options/optimizer-user-options', axiosConfig)
        if (response.data.data) {
          this.optimizerOptions = response.data.data.map(x => ({
            text: x.name,
            value: x.id,
          }))
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async validationForm() {
      let alertResult

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        if (this.remaining_points < 0) {
          await this.$swal({ ...SweetAlert.error, html: 'Remaining Points should be greater than 0' })
        } else {
          this.showOverlay = true

          this.campaspointlimit.remaining_points = this.remaining_points
          const body = this.campaspointlimit

          try {
            const response = await axios.post('campas-point-limit', body, axiosConfig)

            alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

            if (alertResult.value) {
              this.$emit('success')
              this.campaspointlimit = new CampasPointLimit({})
              this.$refs['campas-point-limit-create-modal'].hide()
            }
          } catch (error) {
            await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
          }

          this.showOverlay = false
        }
      }
    },
    campaspointlimitsum(obj) {
      const campaspointlimitkeys = Object.keys(obj)
      let count = 0
      campaspointlimitkeys.forEach(key => {
        if (key !== 'id' && key !== 'user_id' && key !== 'user_name' && key !== 'max_points' && key !== 'remaining_points' && obj[key]) {
          count += parseInt(obj[key], 0)
        }
      })
      const Maxpoint = this.campaspointlimit.max_points || 0
      return (Maxpoint - count)
    },
  },
}
</script>

  //make new list object using map in javascript?
